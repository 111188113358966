<template>
  <div :class="{ 'overlay overlay--visible z-[99998] items-center cursor-pointer': state.open && !state.fixed, 'overlay--invisible': !state.open }" @click.self="close">
    <div v-show="state.open || state.fixed" class="video-player" :class="{ 'video-player--fixed': state.fixed }" ref="element">
      <div class="flex items-center gap-4 px-2">
        <span class="mr-auto text-sm truncate text-neutral-300" :title="state.title">{{ state.title }}</span>
        <button v-if="!state.fixed" @click="pin" :title="i18n.t('Zobrazit v okně')">
          <AtomsImagesIcon icon="arrow-right" size="sm" class="inline-block py-2 cursor-pointer rotate-[45deg] opacity-60 hover:opacity-100 transition-all duration-300" />
        </button>
        <button @click="close" :title="i18n.t('Zavřít')">
          <AtomsImagesIcon icon="cross" size="xs" class="inline-block py-2 cursor-pointer opacity-60 hover:opacity-100 transition-all duration-300" />
        </button>
      </div>
      <div id="player"></div>
    </div>
  </div>
</template>

<script setup>

const state = useVideo().player;

const i18n = useI18n();

let player;

const element = ref(null);

const pin = () => {
  state.value.fixed = true;

  useBaseAnalytics().pushEvent('user_interaction', {
    interaction_name: 'play-video-aside',
    page_element_name: 'element_video'
  });
};

const close = () => {
  state.value.open = false;
  state.value.fixed = false;
  state.value.playing = false;
  state.value.videoId = '';
};

function draggableElement(elmnt) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  elmnt.onmousedown = dragMouseDown;


  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;

    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";

    // update the state
    state.value.x = elmnt.offsetLeft;
    state.value.y = elmnt.offsetTop;
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

onMounted(() => {

  watch(() => state.value.videoId, (videoId) => {
    player && player.destroy();

    if (videoId) {
      player = new YT.Player('player', {
        videoId: videoId,
        events: {
          'onReady': onPlayerReady,
        }
      });
    }
  }, { immediate: true });

  watch(() => state.value.x, () => {
    if (state.value.x && state.value.y) {
      element.value.style.left = `${state.value.x}px`;
      element.value.style.top = `${state.value.y}px`;
    }
  }, { immediate: true });

  draggableElement(element.value);

});

onUnmounted(() => {
  if (player) {
    state.value.time = player.getCurrentTime();
    state.value.playing = player.getPlayerState() === YT.PlayerState.PLAYING;
  }
});

function onPlayerReady(event) {
  event.target.seekTo(state.value.time, true);

  if (!state.value.playing) {
    event.target.pauseVideo();
  }
}
</script>
<style lang="postcss">
.video-player {

  @apply z-[999999] w-[650px] max-w-[95%] aspect-video border-2 border-black bg-black text-white select-none;

  &--fixed {
    @apply fixed left-0 right-0 bottom-0 max-w-[100%] tablet:left-[calc(100%-440px)] tablet:bottom-[10px] tablet:w-[430px] cursor-move;
  }

  iframe {
    @apply w-full h-auto aspect-video;
  }
}
</style>